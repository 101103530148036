<template>
    <!-- mobile menu (loaded within nav header) -->
    <n-dropdown v-if="isMobile" v-model:value="selectedMenuOption" :options="menuOptions" placement="bottom-end" @select="handleMenuOption">
        <n-button>
            <template #icon>
                <n-icon>
                    <menu-icon/>
                </n-icon>
            </template>
        </n-button>
    </n-dropdown>
    <!-- desktop menu (loaded within nav sider)-->
    <n-menu v-else
        :value="selectedMenuOption"
        :options="menuOptions"
        :render-label="renderMenuLabel"
        :render-icon="renderMenuIcon"
        @update:value="handleMenuOption"
    />
</template>

<script>
import { h, ref, defineComponent, onMounted, onUnmounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useMobile } from '@/composables/mobile'
import { useFormatting } from '@/composables/format'
import { useMessage, useLoadingBar, NIcon, NTag } from 'naive-ui'
import { MenuOutlined as MenuIcon } from "@vicons/material";
import { BookmarkOutline as AccountIcon } from "@vicons/ionicons5";
import { DateTime } from 'luxon';


export default defineComponent({
    name: 'AccountMenu',
    components: {
        AccountIcon, MenuIcon,
    },

    setup() {
        const store = useStore()
        const message = useMessage()
        const router = useRouter()
        const route = useRoute()
        const loadingBar = useLoadingBar()
        const { isMobile } = useMobile()
        const { getStatusColor } = useFormatting()

        // refs
        const selectedMenuOption = ref('all')
        const menuOptions = ref([
            {
                label: "all accounts",
                key: "all",
            },
        ])

        // computed properties

        // methods
        const getAccounts = async () => {
            clearMenuData()

            try {
                const response = await store.dispatch('account/getAccounts', {})

                for (const account of response.accounts) {
                    let status = 'grey'; // default status
                    if (account.last_balance_ts) {
                        const lastBalanceDate = DateTime.fromISO(account.last_balance_ts, {zone: 'utc'}).toLocal();  // adjust to the users local timezone
                        const sixHoursAgo = DateTime.now().minus({ hours: 6 });
                        if (lastBalanceDate > sixHoursAgo) {
                            status = 'green';
                        } else {
                            status = 'red';
                        }
                    }

                    if (isMobile()) {
                        menuOptions.value.push({
                            label: () => h('div', { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }, [
                                h('span', account.name),
                                h('span', {
                                    style: {
                                        color: getStatusColor(status),
                                        fontSize: '1em',
                                        lineHeight: '1em',
                                        marginLeft: '8px' // space between label and dot
                                    }
                                }, '●') // Unicode character for the dot
                            ]),
                            key: account.name,
                            disabled: !!account.disabled_at
                        });
                    } else {
                        menuOptions.value.push({
                            label: account.name,
                            key: account.name,
                            status: status,
                            // routerLink: `/account/${account.name}`,
                            disabled: !account.disabled_at ? false : true
                        });
                    }
                }
            }
            catch (err) {
                loadingBar.error()
                message.error(String(err))
            }
        }

        // clear existing options except "all accounts"
        const clearMenuData = () => {
            menuOptions.value = [
                {
                    label: "all accounts",
                    key: "all",
                },
            ];
        }

        // watch
        watch(  // watch the route for changes in the 'account' param
            () => route.params.account,
            (newAccountKey) => {
                if (newAccountKey) {
                    selectedMenuOption.value = newAccountKey
                }
            }
        )

        // lifecycle hooks
        onMounted(() => {
            getAccounts()
        })

        return {
            isMobile: isMobile(),
            // menu related
            selectedMenuOption,
            menuOptions,
            renderMenuLabel(option) {
                if (option.label == 'all accounts') {
                    return option.label;
                }

                return h('div', { 
                    style: { 
                        display: 'flex', 
                        justifyContent: 'space-between', // Pushes items to the edges
                        alignItems: 'center' 
                    } 
                }, [
                    // label text
                    option.routerLink ? 
                        h('router-link', { 
                            props: { to: option.routerLink }, 
                            style: { textDecoration: 'none', color: 'inherit' } 
                        }, [
                            h('span', option.label)
                        ]) :
                        h('span', option.label),

                    // status dot
                    h('span', {
                        style: {
                            color: getStatusColor(option.status),
                            fontSize: '1em',
                            lineHeight: '1em',
                        }
                    }, '●') // unicode character for the dot
                ]);
            },
            renderMenuIcon(option) {
                return h('div', [
                    h(NIcon, null, { default: () => h(AccountIcon) }),
                ]);
            },
            handleMenuOption(key, item) {
                router.push({ name: 'Account', params: { account: key } })
            }
        }
    }
})
</script>

<style scoped>
</style>
